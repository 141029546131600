import { BarChartController } from './bar_chart_component_controller'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

export default class extends BarChartController {
  transformToDataset (series) {
    return undefined
  }

  transformToSeries (series, options, offset = 0) {
    return series.map((s) => {
      return s.title.map((name, index) => {
        return {
          name,
          data: s.data.map((entry) => {
            let y = '-'
            if (index === 0) {
              y = Math.min(...entry.y)
            } else if (index === 1 && entry.y[0] < entry.y[1]) {
              y = entry.y[1] - entry.y[0]
            } else if (index === 2 && entry.y[0] > entry.y[1]) {
              y = entry.y[0] - entry.y[1]
            }

            return [entry.x, y]
          })
        }
      })
    }).flat().map((s, index) => {
      const config = {
        stack: 'Total',
        markArea: this.markArea(s, this.numberOfForecastsValue)
      }
      if (index === 0) {
        config.silent = true
        config.itemStyle = {
          borderColor: 'transparent',
          color: 'transparent'
        }
        config.emphasis = {
          disabled: true
        }
      } else {
        config.itemStyle = {
          color: index === 1 ? '#52ae49' : '#974b78'
        }
      }
      return merge(cloneDeep(this.defaultSeriesStyle), cloneDeep(options), config, s)
    })
  }

  tooltipFormatter () {
    return (params) => {
      const valueFormatter = this.tooltipOptions().valueFormatter
      const value = valueFormatter(params[0].data[1])
      const active = params[1].data[1] === '-' ? 2 : 1

      const activeValue = valueFormatter(params[active].data[1])

      return `
        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params[0].axisValueLabel}</div>
        <div style="margin: 10px 0 0;line-height:1;">
            <div style="margin: 0px 0 0;line-height:1;">
                <div style="margin: 0px 0 0;line-height:1;">
                    ${params[active].marker}
                    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[0].seriesName}</span>
                    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${value}</span>
                    <div style="clear:both"></div>
                </div>
                <div style="clear:both"></div>
            </div>
        </div>
        <div style="margin: 10px 0 0;line-height:1;">
            <div style="margin: 0px 0 0;line-height:1;">
                <div style="margin: 0px 0 0;line-height:1;">
                    ${params[0].marker}
                    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params[active].seriesName}</span>
                    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${activeValue}</span>
                    <div style="clear:both"></div>
                </div>
                <div style="clear:both"></div>
            </div>
        </div>
      `
    }
  }
}
